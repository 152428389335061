import number1 from "../../images/galleryImages/submersible/1.jpg";
import number2 from "../../images/galleryImages/submersible/2.jpg";
import number3 from "../../images/galleryImages/submersible/3.jpg";
import number4 from "../../images/galleryImages/submersible/4.jpg";
import number5 from "../../images/galleryImages/submersible/5.jpg";
import number6 from "../../images/galleryImages/submersible/6.jpg";
import number7 from "../../images/galleryImages/submersible/7.jpg";
import number8 from "../../images/galleryImages/submersible/8.jpg";
import number9 from "../../images/galleryImages/submersible/9.jpg";
import number10 from "../../images/galleryImages/submersible/10.jpg";
import number11 from "../../images/galleryImages/submersible/11.jpg";
import number12 from "../../images/galleryImages/submersible/12.jpg";
import number13 from "../../images/galleryImages/submersible/13.jpg";

const submersibleSliderList = [
  {
    _id: "submersible 001",
    image: number1,
    title: "Título de la imagen de Bombeo Sumergible",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "submersible 002",
    image: number2,
    title: "Título de la imagen de Bombeo Sumergible",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "submersible 003",
    image: number3,
    title: "Título de la imagen de Bombeo Sumergible",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "submersible 004",
    image: number4,
    title: "Título de la imagen de Bombeo Sumergible",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "submersible 005",
    image: number5,
    title: "Título de la imagen de Bombeo Sumergible",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "submersible 006",
    image: number6,
    title: "Título de la imagen de Bombeo Sumergible",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "submersible 007",
    image: number7,
    title: "Título de la imagen de Bombeo Sumergible",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "submersible 008",
    image: number8,
    title: "Título de la imagen de Bombeo Sumergible",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "submersible 009",
    image: number9,
    title: "Título de la imagen de Bombeo Sumergible",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "submersible 010",
    image: number10,
    title: "Título de la imagen de Bombeo Sumergible",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "submersible 011",
    image: number11,
    title: "Título de la imagen de Bombeo Sumergible",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "submersible 012",
    image: number12,
    title: "Título de la imagen de Bombeo Sumergible",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "submersible 013",
    image: number13,
    title: "Título de la imagen de Bombeo Sumergible",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
];
export default submersibleSliderList;
