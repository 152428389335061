import number1 from "../../images/galleryImages/sewageWater/1.jpg";
import number2 from "../../images/galleryImages/sewageWater/2.jpg";
import number3 from "../../images/galleryImages/sewageWater/3.jpg";

const sewageWaterSliderList = [
  {
    _id: "sewage 001",
    image: number1,
    title: "Título de la imagen de Aguas Negras y Residuales",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "sewage 002",
    image: number2,
    title: "Título de la imagen de Aguas Negras y Residuales",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "sewage 003",
    image: number3,
    title: "Título de la imagen de Aguas Negras y Residuales",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
];
export default sewageWaterSliderList;
