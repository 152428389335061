import poolproduct1 from "../../../images/productsImages/poolImages/products/disinfection/hidrotermal/ClorinadorCF-04.jpg";
import poolproduct2 from "../../../images/productsImages/poolImages/products/disinfection/hidrotermal/ClorinadorFlotante.jpg";
import poolproduct3 from "../../../images/productsImages/poolImages/products/disinfection/hidrotermal/UV-F.jpg";
import poolproduct4 from "../../../images/productsImages/poolImages/products/disinfection/hidrotermal/UV-ML.jpg";
import poolproduct5 from "../../../images/productsImages/poolImages/products/disinfection/hidrotermal/SC-Mini-1.jpg";
import poolproduct6 from "../../../images/productsImages/poolImages/products/disinfection/hidrotermal/SC-PRO.jpg";
import poolproduct7 from "../../../images/productsImages/poolImages/products/disinfection/pentair/R171070.jpg";
import poolproduct8 from "../../../images/productsImages/poolImages/products/disinfection/pentair/R320.jpg";
import poolproduct9 from "../../../images/productsImages/poolImages/products/disinfection/pentair/iChlor.jpg";

import pdf4 from "../../../pdf/pool/disinfection/sistema de desinfeccionUV-C ML.pdf";
import pdf5 from "../../../pdf/pool/disinfection/SC-MINI.pdf";
import pdf6 from "../../../pdf/pool/disinfection/SC-PRO.pdf";
import pdf7 from "../../../pdf/pool/disinfection/r171070.pdf";
import pdf8 from "../../../pdf/pool/disinfection/ClorinadorlineaPentair.pdf";
import pdf9 from "../../../pdf/pool/disinfection/iChlor.pdf";

const poolDisinfectionProductList = [
  {
    _id: "poocatdishidro001a",
    image: poolproduct1,
    title: "Clorinador en línea",
    code: "",
    category: "Hidrotermal",
    brand: "Hidrotermal",
    subcategory: "Clorinador En Linea",
    downLoad: "none",
  },
  {
    _id: "poocatdishidro002a",
    image: poolproduct2,
    title: "Clorinador flotante",
    code: "",
    category: "Hidrotermal",
    brand: "Hidrotermal",
    subcategory: "Clorinador Flotante",
    downLoad: "none",
  },
  {
    _id: "poocatdishidro003a",
    image: poolproduct3,
    title: "Purificador UV-F",
    code: "UV-F",
    category: "Hidrotermal",
    brand: "Hidrotermal",
    subcategory: "Purificador UV-F",
    downLoad: "none",
  },
  {
    _id: "poocatdishidro004a",
    image: poolproduct4,
    title: "Purificador UV-ML",
    code: "UV-ML",
    category: "Hidrotermal",
    brand: "Hidrotermal",
    subcategory: "Purificador UV-ML",
    downLoad: pdf4,
  },
  {
    _id: "poocatdishidro005a",
    image: poolproduct5,
    title: "Generador de clorosal SC-MINI",
    code: "SC-MINI",
    category: "Hidrotermal",
    brand: "Hidrotermal",
    subcategory: "SC-MINI",
    downLoad: pdf5,
  },
  {
    _id: "poocatdishidro006a",
    image: poolproduct6,
    title: "Generador de clorosal SC-PRO",
    code: "SC-PRO",
    category: "Hidrotermal",
    brand: "Hidrotermal",
    subcategory: "SC-PRO",
    downLoad: pdf6,
  },
  {
    _id: "poocatdispent001a",
    image: poolproduct7,
    title: "Clorinador R171070",
    code: "R171070",
    category: "Pentair",
    brand: "Pentair",
    subcategory: "Clorinador R171070",
    downLoad: pdf7,
  },
  {
    _id: "poocatdispent002a",
    image: poolproduct8,
    title: "Clorinador Rainbow",
    code: "R320",
    category: "Pentair",
    brand: "Pentair",
    subcategory: "Clorinador Rainbow R320",
    downLoad: pdf8,
  },
  {
    _id: "poocatdispent003a",
    image: poolproduct9,
    title: "Generador de cloro sal iChlor",
    code: "",
    category: "Pentair",
    brand: "Pentair",
    subcategory: "iChlor",
    downLoad: pdf9,
  },
];

export default poolDisinfectionProductList;
