import poolproduct1 from "../../../images/productsImages/poolImages/products/light/hidrotermal/light-01.jpg";
import poolproduct2 from "../../../images/productsImages/poolImages/products/light/hidrotermal/light-02.jpg";
import poolproduct3 from "../../../images/productsImages/poolImages/products/light/hidrotermal/light-03.jpg";
import poolproduct4 from "../../../images/productsImages/poolImages/products/light/hidrotermal/light-04.jpg";
import poolproduct5 from "../../../images/productsImages/poolImages/products/light/hidrotermal/light-05.jpg";
import poolproduct6 from "../../../images/productsImages/poolImages/products/light/hidrotermal/light-06.jpg";
import poolproduct7 from "../../../images/productsImages/poolImages/products/light/hidrotermal/light-07.jpg";
import poolproduct8 from "../../../images/productsImages/poolImages/products/light/pentair/GloBritme-3.png";
import poolproduct9 from "../../../images/productsImages/poolImages/products/light/pentair/microBrite.png";

import pdf1 from "../../../pdf/pool/light/Manual-of-Hidro-CTRL.pdf";
import pdf3 from "../../../pdf/pool/light/UltraThin.pdf";
import pdf4 from "../../../pdf/pool/light/PanelUltraThinLedColoryBlanco.pdf";
import pdf5 from "../../../pdf/pool/light/PW100.pdf";
import pdf6 from "../../../pdf/pool/light/SW200.pdf";
import pdf7 from "../../../pdf/pool/light/SW300.pdf";
import pdf8 from "../../../pdf/pool/light/BrochureGlobrite.pdf";
import pdf9 from "../../../pdf/pool/light/microbrite.pdf";

const poolLightProductList = [
  {
    _id: "poocatlighidro001a",
    image: poolproduct1,
    title: "CONTROL HIDRO-CTRL",
    code: "",
    category: "Hidrotermal",
    brand: "Hidrotermal",
    subcategory: "CONTROL HIDRO-CTRL",
    downLoad: pdf1,
  },
  {
    _id: "poocatlighidro002a",
    image: poolproduct2,
    title: "CONTROL HIDRO-SYNC",
    code: "",
    category: "Hidrotermal",
    brand: "Hidrotermal",
    subcategory: "CONTROL HIDRO-SYNC",
    downLoad: "none",
  },
  {
    _id: "poocatlighidro003a",
    image: poolproduct3,
    title: "PANEL INOX ULTRA THIN LED BLANCO Y COLOR",
    code: "",
    category: "Hidrotermal",
    brand: "Hidrotermal",
    subcategory: "PANEL INOX ULTRA THIN LED BLANCO Y COLOR",
    downLoad: pdf3,
  },
  {
    _id: "poocatlighidro004a",
    image: poolproduct4,
    title: "PANEL ULTRA THIN BLANCO Y COLOR 35W",
    code: "",
    category: "Hidrotermal",
    brand: "Hidrotermal",
    subcategory: "PANEL ULTRA THIN BLANCO Y COLOR 35W",
    downLoad: pdf4,
  },
  {
    _id: "poocatlighidro005a",
    image: poolproduct5,
    title: "SERIE PW100 LED COLOR Y BLANCO 35W",
    code: "",
    category: "Hidrotermal",
    brand: "Hidrotermal",
    subcategory: "SERIE PW100 LED COLOR Y BLANCO 35W",
    downLoad: pdf5,
  },
  {
    _id: "poocatlighidro006a",
    image: poolproduct6,
    title: "SERIE SW200 LED COLOR Y BLANCO 35W",
    code: "",
    category: "Hidrotermal",
    brand: "Hidrotermal",
    subcategory: "SERIE SW200 LED COLOR Y BLANCO 35W",
    downLoad: pdf6,
  },
  {
    _id: "poocatlighidro007a",
    image: poolproduct7,
    title: "SERIE SW300 LED COLOR Y BLANCO 35W",
    code: "",
    category: "Hidrotermal",
    brand: "Hidrotermal",
    subcategory: "SERIE SW300 LED COLOR Y BLANCO 35W",
    downLoad: pdf7,
  },
  {
    _id: "poocatligpent001a",
    image: poolproduct8,
    title: "Globrite",
    code: "",
    category: "Pentair",
    brand: "Pentair",
    subcategory: "Globrite",
    downLoad: pdf8,
  },
  {
    _id: "poocatligpent002a",
    image: poolproduct9,
    title: "MicroBrite",
    code: "",
    category: "Pentair",
    brand: "Pentair",
    subcategory: "MicroBrite",
    downLoad: pdf9,
  },
];

export default poolLightProductList;
