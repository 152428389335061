import React from "react";
import classes from "./Home.module.css";
import Card from "../components/UI/Card";
import servicesList from "../Lists/servicesList";
import HeaderBackground from "../images/background.jpg";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Home = () => {
  const homeBackground = {
    width: "100%",
    backgroundImage: `url(${HeaderBackground})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div>
      <Helmet>
        <title>Home</title>
        <meta
          name="Alrotek de Centroamerica"
          content="Asesoramiento y venta de sistemas de bombeo taller de servicio mantenimiento ingeniería y diseño de sistemas mecánicos alquiler de bombas y revisión de pozos. costa rica y centroamerica"
        />
      </Helmet>
      <div style={homeBackground} className={classes.homeHead}>
        <div className={classes.message}>
          <h1>Impulsamos el agua a otro nivel</h1>
          <h5>
            Somos una empresa costarricense, dedicada a brindar soluciones en el
            tema del Agua.
          </h5>
        </div>
      </div>
      <section className={classes.aboutUs}>
        <div className={classes.principalInfo}>
          <div className={classes.description}>
            <h4>Acerca de Nosotros:</h4>
            <h2>Bienvenido a Alrotek de Centroamérica S.A.</h2>
            <hr />
            <p>
              Somos una empresa orientada en la importación y venta de productos
              de bombeo y afines, así como al servicio en soporte técnico,
              asesoría e instalación de equipos de bombeo, siempre en busca de
              la excelencia y en la constante innovación que el la tecnología
              nos ofrece para generar confianza en nuestros clientes y
              proveedores. Cordialmente te invitamos a ser parte de nuestro
              equipo para continuar creciendo juntos.
            </p>
          </div>
        </div>
        <div className={classes.mishisvis}>
          <div className={classes.mision}>
            <h4>Misión</h4>
            <p>
              Ofrecer al cliente un servicio de excelencia en el suministro e
              instalación de equipos de bombeo y afines, con soluciones
              oportunas y acordes con las necesidades y demandas.
            </p>
          </div>
          <div className={classes.vision}>
            <h4>Visión</h4>
            <p>
              Ser la empresa líder en el país en el suministro, asesoría e
              instalación de equipos de bombeo y afines, mediante un excelente
              servicio al cliente y la mayor disponibilidad en equipos de alta
              calidad para la satisfacción de las necesidades de nuestros
              clientes.
            </p>
          </div>
          <div className={classes.history}>
            <h4>Historia</h4>
            <p>
              Somos una empresa de servicios, especializada en la importación,
              suministro e instalación de sistemas de bombeo y afines. Contamos
              con 17 años de existencia en el mercado costarricense y
              centroamericano, brindando la mejor asistencia técnica y
              profesional a nuestros clientes. Actualmente contamos un gran
              equipo de profesionales y técnicos certificados que nos permiten
              dar un soporte especializado y oportuno, así como un repertorio de
              proyectos realizados satisfactoriamente que brindan confianza a
              nuestros clientes. Mantenemos un inventario permanente de gran
              variedad de equipos de bombeo con las mejores marcas a nivel
              mundial que nos permiten resolver la mayoría de las necesidades de
              equipos especializados relacionados con el movimiento de líquidos.
            </p>
          </div>
        </div>
      </section>
      <section className={classes.servicesSection}>
        <h1>Nuestros servicios</h1>
        <div className={classes.services}>
          {servicesList.map((service) => (
            <Card
              id={service._id}
              key={service._id}
              className={classes.servicesCard}
            >
              <Link
                style={{ textDecoration: "none" }}
                className={classes.servicesLink}
                to={service.link}
              >
                <img src={service.image} alt={service.title}></img>
                <div>
                  <h4>{service.title}</h4>
                  <p className={classes.black}>{service.text}</p>
                </div>
              </Link>
            </Card>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Home;
