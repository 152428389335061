import React from "react";
import ProductMenu from "../../components/UI/Productmenu";
import { poolList } from "../../Lists/products/poolList";
import classes from "./General.module.css";
import { Helmet } from "react-helmet";

const Pool = () => {
  return (
    <div className={classes.productMenuPage}>
      <Helmet>
        <title>Productos de piscina</title>
        <meta
          name="Equipos y accesorios para piscina"
          content="Somos suplidores de las principales marcas de equipos de piscina, como lo son Pentair Pool e Hidrotermal."
        />
      </Helmet>
      <div className={classes.productMainInfo}>
        <h1>Equipos y accesorios para piscina</h1>
        <h4>
          Somos suplidores de las principales marcas de equipos de piscina, como
          lo son Pentair Pool e Hidrotermal.
        </h4>
        <p>
          Contamos con un amplio inventario en nuestras bodegas para una
          atención inmediata en la mayoría de las necesidades de equipos de
          bombeo y accesorios para piscina. Contamos con la última tecnología en
          sistemas de bombeo y filtración, sistemas de control, cloración e
          iluminación.
        </p>
      </div>
      <ProductMenu list={poolList}></ProductMenu>
    </div>
  );
};

export default Pool;
