import React from "react";
import classes from "./Cardmenu.module.css";
import { Link } from "react-router-dom";

const Cardmenu = (props) => {
  return (
    <div className={`${classes.cardMenu} ${props.className}`}>
      <Link style={{ textDecoration: "none" }} to={props.link}>
        <img
          className={classes.optionImage}
          src={props.image}
          alt={props.title}
        ></img>
        <div className={classes.infoOption}>
          <h4 className={classes.titleOption}>{props.title}</h4>
          <img
            className={classes.optionIcon}
            src={props.icon}
            alt={props.title}
          ></img>
        </div>
      </Link>
    </div>
  );
};

export default Cardmenu;
