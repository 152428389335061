import number1 from "../../images/galleryImages/surface/1.jpg";
import number2 from "../../images/galleryImages/surface/2.jpg";
import number3 from "../../images/galleryImages/surface/3.jpg";
import number4 from "../../images/galleryImages/surface/4.jpg";
import number5 from "../../images/galleryImages/surface/5.jpg";
import number6 from "../../images/galleryImages/surface/6.jpg";
import number7 from "../../images/galleryImages/surface/7.jpg";
import number8 from "../../images/galleryImages/surface/8.jpg";
import number9 from "../../images/galleryImages/surface/9.jpg";
import number10 from "../../images/galleryImages/surface/10.jpg";
import number11 from "../../images/galleryImages/surface/11.jpg";
import number12 from "../../images/galleryImages/surface/12.jpg";

const surfaceSliderList = [
  {
    _id: "surface 001",
    image: number1,
    title: "Título de la imagen de Bombeo de Superficie",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "surface 002",
    image: number2,
    title: "Título de la imagen de Bombeo de Superficie",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "surface 003",
    image: number3,
    title: "Título de la imagen de Bombeo de Superficie",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "surface 004",
    image: number4,
    title: "Título de la imagen de Bombeo de Superficie",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "surface 005",
    image: number5,
    title: "Título de la imagen de Bombeo de Superficie",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "surface 006",
    image: number6,
    title: "Título de la imagen de Bombeo de Superficie",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "surface 007",
    image: number7,
    title: "Título de la imagen de Bombeo de Superficie",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "surface 008",
    image: number8,
    title: "Título de la imagen de Bombeo de Superficie",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "surface 009",
    image: number9,
    title: "Título de la imagen de Bombeo de Superficie",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "surface 010",
    image: number10,
    title: "Título de la imagen de Bombeo de Superficie",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "surface 011",
    image: number11,
    title: "Título de la imagen de Bombeo de Superficie",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "surface 012",
    image: number12,
    title: "Título de la imagen de Bombeo de Superficie",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
];
export default surfaceSliderList;
