import React from "react";
import { useState, useEffect } from "react";
import classes from "./carousel.module.css";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { BsDot } from "react-icons/bs";

const Newcarousel = (props) => {
  const carouselArray = props.array;
  const length = carouselArray.length;
  const [index, setIndex] = useState(0);
  const [paused, setPaused] = useState(false);

  const handlePrevious = () => {
    const newIndex = index - 1;
    if (newIndex < 0) {
      setIndex(length - 1);
    } else {
      setIndex(newIndex);
    }
  };

  const handleNext = () => {
    const newIndex = index + 1;

    if (newIndex === length) {
      setIndex(0);
    } else {
      setIndex(newIndex);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!paused) {
        handleNext();
      }
    }, 3000);
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  });

  return (
    <div className={`${classes.mainCarousel} ${props.className}`}>
      <div className={classes.text}>
        <div className={classes.title}>
          <h1>{props.title}</h1>
          <img
            src={props.image}
            alt={props.title}
            className={classes.iconImg}
          ></img>
        </div>
      </div>
      <div
        className={classes.carousel}
        onMouseEnter={() => setPaused(true)}
        onMouseLeave={() => setPaused(false)}
      >
        <button className={classes.arrowPrev} onClick={handlePrevious}>
          <IoIosArrowBack size={30} />
        </button>
        <img
          src={carouselArray[index].image}
          alt={props.title}
          className={classes.carImg}
          onClick={() => {
            props.onShowImage(carouselArray[index]._id);
          }}
        ></img>
        <button className={classes.arrowNext} onClick={handleNext}>
          <IoIosArrowForward size={30} />
        </button>
      </div>
      <div className={classes.indicators}>
        {carouselArray.map((item, itemIndex) => (
          <button
            key={item._id}
            className={`${
              itemIndex === index ? classes.active : classes.normal
            }`}
            onClick={() => {
              setIndex(itemIndex);
            }}
          >
            <BsDot size={20} />
          </button>
        ))}
      </div>
    </div>
  );
};

export default Newcarousel;
