import React, { useState } from "react";
import { submersibleList } from "../../../Lists/products/submersibleList";
import brandsList from "../../../Lists/brands";
import Category from "../../../components/UI/Category";
import classes from "./submersibleProducts.module.css";
import Subcategory from "../../../components/Subcategorymenu";
import { useParams } from "react-router-dom";
import submersibleAccessoriesProductList from "../../../Lists/products/submersibleProducts/submersibleAccessoriesList";
import submersibleIndustrialProductList from "../../../Lists/products/submersibleProducts/submersibleIndustrialList";
import submersibleResidentialProductList from "../../../Lists/products/submersibleProducts/submersibleResidentialList";
import submersibleSolarBombProductList from "../../../Lists/products/submersibleProducts/submersibleSolarBombList";
import franklinLogo from "../../../images/brands/brands_FRANKLIN.svg";

const SubmersibleProducts = () => {
  const newArray = [];

  const params = useParams();

  const solarBomb = "bombassolares" === params.submersibleId;

  const FranklinElectricBrand = {
    image: franklinLogo,
    alt: "Franklin Electric",
  };

  const showSubcategory = () => {
    if ("bombassolares" === params.submersibleId) {
      return;
    } else
      return (
        <section>
          <Subcategory
            onListChange={onListChange}
            onsubListChange={onsubListChange}
            categoryList={listCategory.list}
          ></Subcategory>
        </section>
      );
  };

  const listCategory = submersibleList.find(
    (category) => category.path === params.submersibleId
  );

  const findListHandle = (path) => {
    if (path === "accessorios") {
      return submersibleAccessoriesProductList;
    }
    if (path === "bombassolares") {
      return submersibleSolarBombProductList;
    }
    if (path === "industrial") {
      return submersibleIndustrialProductList;
    }
    if (path === "residencial") {
      return submersibleResidentialProductList;
    }
  };
  const findBrandHandle = (path) => {
    if (path === "bombassolares") {
      return FranklinElectricBrand;
    } else {
      return "none";
    }
  };

  const originalList = findListHandle(listCategory.path);

  const [displayedList, setdisplayedList] = useState(
    findListHandle(listCategory.path)
  );
  const [displayedBrand, setdisplayedBrand] = useState(
    findBrandHandle(listCategory.path)
  );

  const onListChange = (category) => {
    newArray.length = 0;
    originalList.map((element) => sort(element, category));
    setdisplayedList(newArray);
    setdisplayedBrand(findBrand(category));
  };

  const sort = (element, category) => {
    if (element.category === category) {
      newArray.push(element);
    }
  };

  const findBrand = (brand) => {
    if (brand === "Boshart") {
      return brandsList[16];
    }
    if (brand === "Flomatic") {
      return brandsList[10];
    }
    if (brand === "Kalas") {
      return brandsList[4];
    }
    if (brand === "Pearl") {
      return brandsList[0];
    }
    if (brand === "Franklin Electric") {
      return FranklinElectricBrand;
    }
    if (brand === "Alta Eficiencia") {
      return FranklinElectricBrand;
    }
    if (brand === "BGP") {
      return brandsList[7];
    }
    if (brand === "Little Giant") {
      return brandsList[5];
    }
    if (brand === "Cajas de arranque") {
      return FranklinElectricBrand;
    }
    if (brand === "Pumptec") {
      return FranklinElectricBrand;
    }
    if (brand === "Submonitor") {
      return FranklinElectricBrand;
    }
    if (brand === "Check pascon") {
      return brandsList[16];
    }
    if (brand === "Sellos de pozo") {
      return brandsList[16];
    } else return "none";
  };

  const onsubListChange = (category, secondCategory) => {
    newArray.length = 0;
    originalList.map((element) =>
      secondsort(element, category, secondCategory)
    );

    setdisplayedList(newArray);
    setdisplayedBrand(findBrand(secondCategory));
  };
  const secondsort = (element, category, secondCategory) => {
    if (
      element.category === category &&
      element.subcategory === secondCategory
    ) {
      newArray.push(element);
    }
  };

  return (
    <div className={classes.showProducts}>
      {showSubcategory()}
      <section
        className={` ${solarBomb ? classes.onlyProducts : classes.products}`}
      >
        <Category
          brand={displayedBrand}
          title={listCategory.title}
          list={displayedList}
        ></Category>
      </section>
    </div>
  );
};

export default SubmersibleProducts;
