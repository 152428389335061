import React from "react";
import brandsList from "../Lists/brands";
import classes from "./Brand.module.css";
import Franklinelectric from "../components/Franklinanchor";

import { Helmet } from "react-helmet";

const Brand = () => {
  return (
    <div className={classes.brandPage}>
      <Helmet>
        <title>Home</title>
        <meta
          name="Alrotek de Centroamerica marcas"
          content="Franklin Electric Pearl Water Systems Kalas Pentair globalwater solutions hidrotermal littlegiant armstrong bgp aquastrong arvek flomatic valves grunfos schneider electric wilo pioneerpump ge industrial motors"
        />
      </Helmet>
      <h1>Marcas</h1>
      <Franklinelectric></Franklinelectric>
      <section>
        <div className={classes.sectionBrand}>
          {brandsList.map((brand) => (
            <div className={classes.brand} key={brand._id}>
              <img src={brand.image} alt={brand.alt}></img>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Brand;
