import number1 from "../../images/galleryImages/hydroneumatic/1.jpg";
import number2 from "../../images/galleryImages/hydroneumatic/2.jpg";
import number3 from "../../images/galleryImages/hydroneumatic/3.jpg";
import number4 from "../../images/galleryImages/hydroneumatic/4.jpg";
import number5 from "../../images/galleryImages/hydroneumatic/5.jpg";

const hidroneumaticSliderList = [
  {
    _id: "hydroneumatic 001",
    image: number1,
    title: "Título de Presión Hidroneumático",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "hydroneumatic 002",
    image: number2,
    title: "Título de Presión Hidroneumático",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "hydroneumatic 003",
    image: number3,
    title: "Título de Presión Hidroneumático",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "hydroneumatic 004",
    image: number4,
    title: "Título de Presión Hidroneumático",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "hydroneumatic 005",
    image: number5,
    title: "Título de Presión Hidroneumático",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
];
export default hidroneumaticSliderList;
