import poolproduct1 from "../../../images/productsImages/poolImages/products/chemicals/Alguicida.png";
import poolproduct2 from "../../../images/productsImages/poolImages/products/chemicals/Clarificador.png";
import poolproduct3 from "../../../images/productsImages/poolImages/products/chemicals/CLOROSHOCK.png";
import poolproduct4 from "../../../images/productsImages/poolImages/products/chemicals/floculante.png";
import poolproduct5 from "../../../images/productsImages/poolImages/products/chemicals/MASPHINFO.png";
import poolproduct6 from "../../../images/productsImages/poolImages/products/chemicals/MASPHLIQUIDO.png";
import poolproduct7 from "../../../images/productsImages/poolImages/products/chemicals/MENOSPH.png";
import poolproduct8 from "../../../images/productsImages/poolImages/products/chemicals/MENOSPHLIQUIDO.png";
import poolproduct9 from "../../../images/productsImages/poolImages/products/chemicals/Triclorogranulado.png";
import poolproduct10 from "../../../images/productsImages/poolImages/products/chemicals/TricloroTableta.png";
import pdf4 from "../../../pdf/pool/chemicals/floculante.pdf";
import pdf5 from "../../../pdf/pool/chemicals/masph.pdf";
import pdf6 from "../../../pdf/pool/chemicals/masphliquido.pdf";
import pdf7 from "../../../pdf/pool/chemicals/menosph.pdf";
import pdf8 from "../../../pdf/pool/chemicals/menosphliquido.pdf";
import pdf9 from "../../../pdf/pool/chemicals/triclorogranulado.pdf";
import pdf10 from "../../../pdf/pool/chemicals/triclorotableta.pdf";

const poolChemicalProductList = [
  {
    _id: "poocatche001a",
    image: poolproduct1,
    title: "Alguicida",
    code: "",
    downLoad: "none",
  },
  {
    _id: "poocatche002a",
    image: poolproduct2,
    title: "Clarificador",
    code: "",
    downLoad: "none",
  },
  {
    _id: "poocatche003a",
    image: poolproduct3,
    title: "Cloro Shock",
    code: "",
    downLoad: "none",
  },
  {
    _id: "poocatche004a",
    image: poolproduct4,
    title: "Floculante",
    code: "",
    downLoad: pdf4,
  },
  {
    _id: "poocatche005a",
    image: poolproduct5,
    title: "Más PH",
    code: "",
    downLoad: pdf5,
  },
  {
    _id: "poocatche006a",
    image: poolproduct6,
    title: "Más PH Líquido",
    code: "",
    downLoad: pdf6,
  },
  {
    _id: "poocatche007a",
    image: poolproduct7,
    title: "Menos PH",
    code: "",
    downLoad: pdf7,
  },
  {
    _id: "poocatche008a",
    image: poolproduct8,
    title: "Menos PH Líquido",
    code: "",
    downLoad: pdf8,
  },
  {
    _id: "poocatche009a",
    image: poolproduct9,
    title: "Tricloro Granulado",
    code: "",
    downLoad: pdf9,
  },
  {
    _id: "poocatche0010a",
    image: poolproduct10,
    title: "Tricloro Tableta",
    code: "",
    downLoad: pdf10,
  },
];

export default poolChemicalProductList;
