import React from "react";
import classes from "./WindowImage.module.css";
import Modal from "../UI/Modals/Modal";

const WindowImage = (props) => {
  console.log(props.object._id);
  return (
    <Modal onClose={props.onHideImage}>
      <div className={classes.total}>
        <img src={props.object.image} alt="titulo de la imagen" />
      </div>
      <div className={classes.buttonRight}>
        <button onClick={props.onHideImage} className="btn btn-primary">
          Close
        </button>
      </div>
    </Modal>
  );
};
export default WindowImage;
