import React from "react";
import classes from "./footer.module.css";
import FacebookButton from "../components/Facebook";
import IstagramButton from "../components/Instagram";

const Footer = () => {
  return (
    <div className={classes.footer}>
      <div className={classes.info}>
        <div className={classes.contInfo}>
          <h2>UBICACIÓN</h2>
          <p>25 metros norte de la intersección</p>
          <p>Palmares, Alajuela, Costa Rica.</p>
        </div>
        <div className={classes.contInfo}>
          <h2>HORARIO</h2>
          <p>Lunes a viernes 7:30a.m. - 6:00p.m.</p>
        </div>
        <div className={classes.contInfo}>
          <h2>CONTACTO</h2>
          <p>info@alrotek.com</p>
          <p>Tel: 2452-1046</p>
        </div>
      </div>
      <div className={classes.socialMediaIcons}>
        <FacebookButton></FacebookButton>
        <IstagramButton></IstagramButton>
      </div>
      <div className={classes.derechos}>
        <p>Todos los derechos reservados. Alrotek, 2023.</p>
      </div>
    </div>
  );
};

export default Footer;
