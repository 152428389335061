import React from "react";
import classes from "./Template.module.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Template = (props) => {
  return (
    <div className={`${classes.pageCard} ${props.className}`}>
      <Helmet>
        <title>{props.title}</title>
        <meta name={props.title} content={props.text2} />
      </Helmet>
      <div className={classes.textInfo}>
        <h1>{props.title}</h1>
        <h4>{props.text2}</h4>
        <p>{props.text}</p>
        <Link type="button" className="btn btn-primary" to="/contactenos">
          {props.buttonName}
        </Link>
        {props.children}
      </div>
      <div>
        <img
          src={props.image}
          className={classes.templateImg}
          alt={props.title}
        ></img>
      </div>
    </div>
  );
};

export default Template;
