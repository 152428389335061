import React from "react";
import classes from "./Subcategory.module.css";

const Subcategory = (props) => {
  const button = {
    margin: "5px",
    width: "190px",
    whiteSpace: "normal",
  };

  const { onListChange, onsubListChange, categoryList } = props;

  const handlesubmenu = (category) => {
    if (category.hasOwnProperty("subcategory")) {
      return (
        <li id={category._id} key={category._id} className="btn-group">
          <button
            style={button}
            type="button"
            className="btn btn-outline-primary dropdown-toggle btn-block"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            onClick={() => onListChange(category.title)}
          >
            {category.title}
          </button>
          <ul className="dropdown-menu">
            {category.subcategory.map((subcategory) => (
              <li
                id={subcategory._id}
                key={subcategory._id}
                className="dropdown-item"
              >
                <button
                  className="btn btn-light"
                  onClick={() =>
                    onsubListChange(category.title, subcategory.name)
                  }
                >
                  {subcategory.name}
                </button>
              </li>
            ))}
          </ul>
        </li>
      );
    } else
      return (
        <li className="btn-group" id={category._id} key={category._id}>
          <button
            style={button}
            type="button"
            className="btn btn-outline-primary"
            onClick={() => onListChange(category.title)}
          >
            {category.title}
          </button>
        </li>
      );
  };

  return (
    <div className={classes.menu}>
      <h4>CATEGORIAS</h4>
      <ul className={classes.subMenu}>
        {categoryList.map((category) => handlesubmenu(category))}
      </ul>
    </div>
  );
};

export default Subcategory;
