import React from "react";
import franklinLogo from "../images/brands/brands_FRANKLIN.svg";
import classes from "./Brandanchor.module.css";

const Franklinelectric = () => {
  return (
    <div className={classes.facebook}>
      <img
        className={classes.principalBrand}
        src={franklinLogo}
        alt={"Franklin Electric"}
      ></img>
    </div>
  );
};
export default Franklinelectric;
