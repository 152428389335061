import React from "react";
import Category from "../../components/UI/Category";
import hydroneumaticList from "../../Lists/products/hydroNeumaticList";
import classes from "./Products.module.css";
import littleG from "../../images/brands/brands_LITTLE.svg";
import { Helmet } from "react-helmet";

const Hydropneumatic = () => {
  const pageTitle = "Sistemas de presión hidroneumáticos";
  const littleGiantBrand = {
    image: littleG,
    alt: "Little Giant",
  };
  return (
    <div className={classes.showStoreList}>
      <Helmet>
        <title>{pageTitle}</title>
        <meta
          name={pageTitle}
          content="sistemas de presión
          hidroneumáticos para instalaciones en casas edificios"
        />
      </Helmet>
      <div className={classes.familyMainInfo}>
        <h1>Sistemas de presión hidroneumáticos</h1>
        <p>
          Mantenemos en inventario permanente sistemas de presión
          hidroneumáticos para sus instalaciones en casas y pequeños edificios,
          tanto con bombas sumergibles que minimizan el ruido, como con bombas
          externas
        </p>
      </div>
      <Category list={hydroneumaticList} brand={littleGiantBrand}></Category>
    </div>
  );
};

export default Hydropneumatic;
