import number1 from "../../images/galleryImages/pool/1.jpg";
import number2 from "../../images/galleryImages/pool/2.jpg";
import number3 from "../../images/galleryImages/pool/3.jpg";
import number4 from "../../images/galleryImages/pool/4.jpg";
import number5 from "../../images/galleryImages/pool/5.jpg";
import number6 from "../../images/galleryImages/pool/6.jpg";
import number7 from "../../images/galleryImages/pool/7.jpg";

const poolSliderList = [
  {
    _id: "pool 001",
    image: number1,
    title: "Título de la imagen de piscinas",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "pool 002",
    image: number2,
    title: "Título de la imagen de piscinas",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "pool 003",
    image: number3,
    title: "Título de la imagen de piscinas",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "pool 004",
    image: number4,
    title: "Título de la imagen de piscinas",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "pool 005",
    image: number5,
    title: "Título de la imagen de piscinas",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "pool 006",
    image: number6,
    title: "Título de la imagen de piscinas",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "pool 007",
    image: number7,
    title: "Título de la imagen de piscinas",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
];
export default poolSliderList;
