import React from "react";
import { BsInstagram } from "react-icons/bs";
import classes from "./socialMedia.module.css";

const IstagramButton = () => {
  return (
    <div className={classes.instagram}>
      <a href="https://www.instagram.com/alrotekca/?hl=es">
        <BsInstagram className={classes.icon} />
      </a>
    </div>
  );
};
export default IstagramButton;
