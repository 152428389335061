import React from "react";
import Category from "../../components/UI/Category";
import pressureList from "../../Lists/products/pressureList";
import classes from "./Products.module.css";
import franklinELogo from "../../images/brands/brands_FRANKLIN.svg";
import { Helmet } from "react-helmet";

const Pressure = () => {
  const pageTitle = "Sistemas de Presión Constante";
  const FranklinElectricBrand = {
    image: franklinELogo,
    alt: "Franklin Electric",
  };
  return (
    <div className={classes.showStoreList}>
      <Helmet>
        <title>{pageTitle}</title>
        <meta
          name={pageTitle}
          content="sistemas de presión constante importados directamente o ensamblados en nuestros talleres"
        />
      </Helmet>
      <div className={classes.familyMainInfo}>
        <h1>{pageTitle}</h1>
        <p>
          Contamos con gran variedad de sistemas de presión constante, ya sea
          importados directamente o ensamblados en nuestros talleres, para
          satisfacer necesidades pequeñas en casas de habitación o sistemas
          complejos para edificios, industria y hotelería
        </p>
      </div>
      <Category brand={FranklinElectricBrand} list={pressureList}></Category>
    </div>
  );
};

export default Pressure;
