import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Category from "../../../components/UI/Category";
import tankList from "../../../Lists/products/tankList";
import classes from "../Products.module.css";
import global from "../../../images/brands/brands_GLOBAL.svg";

const TanksProducts = () => {
  const params = useParams();
  const GlobalwaterBrand = {
    image: global,
    alt: "Globalwater solutions",
  };
  const store = tankList.find((store) => store.path === params.tankId);
  const findBrandHandle = (path) => {
    if (path === "fibradevidrio") {
      return GlobalwaterBrand;
    } else {
      return "none";
    }
  };
  const [displayedBrand, setdisplayedBrand] = useState(
    findBrandHandle(store.path)
  );

  if (!store) {
    return <p>No products were found matching your selection</p>;
  }
  return (
    <div className={classes.showStoreList}>
      <div className={classes.tankFamilyMainInfo}>
        <Category
          brand={displayedBrand}
          title={store.title}
          list={store.list}
        ></Category>
      </div>
    </div>
  );
};

export default TanksProducts;
