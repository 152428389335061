import number1 from "../../images/galleryImages/fire/1.jpg";
import number2 from "../../images/galleryImages/fire/2.jpg";
import number3 from "../../images/galleryImages/fire/3.jpg";
import number4 from "../../images/galleryImages/fire/4.jpg";
import number5 from "../../images/galleryImages/fire/5.jpg";
import number6 from "../../images/galleryImages/fire/6.jpg";
import number7 from "../../images/galleryImages/fire/7.jpg";
import number8 from "../../images/galleryImages/fire/8.jpg";
import number9 from "../../images/galleryImages/fire/9.jpg";
import number10 from "../../images/galleryImages/fire/10.jpg";
import number11 from "../../images/galleryImages/fire/11.jpg";

const fireSliderList = [
  {
    _id: "fire 001",
    image: number1,
    title: "Título de la imagen de Equipo contra Incendios",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "fire 002",
    image: number2,
    title: "Título de la imagen de Equipo contra Incendios",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "fire 003",
    image: number3,
    title: "Título de la imagen de Equipo contra Incendios",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "fire 004",
    image: number4,
    title: "Título de la imagen de Equipo contra Incendios",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "fire 005",
    image: number5,
    title: "Título de la imagen de Equipo contra Incendios",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "fire 006",
    image: number6,
    title: "Título de la imagen de Equipo contra Incendios",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "fire 007",
    image: number7,
    title: "Título de la imagen de Equipo contra Incendios",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "fire 008",
    image: number8,
    title: "Título de la imagen de Equipo contra Incendios",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "fire 009",
    image: number9,
    title: "Título de la imagen de Equipo contra Incendios",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "fire 0010",
    image: number10,
    title: "Título de la imagen de Equipo contra Incendios",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "fire 0011",
    image: number11,
    title: "Título de la imagen de Equipo contra Incendios",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
];
export default fireSliderList;
