import submersibleproduct1 from "../../../images/productsImages/submersibleImages/products/Industrial/AguasNegras/OS.jpg";
import submersibleproduct2 from "../../../images/productsImages/submersibleImages/products/Industrial/AguasNegras/OSC.jpg";
import submersibleproduct3 from "../../../images/productsImages/submersibleImages/products/Industrial/AguasNegras/OSG.jpg";
import submersibleproduct4 from "../../../images/productsImages/submersibleImages/products/Industrial/AguasNegras/SerieFWS.png";
import submersibleproduct5 from "../../../images/productsImages/submersibleImages/products/Industrial/AguasNegras/20E.jpg";
import submersibleproduct6 from "../../../images/productsImages/submersibleImages/products/Industrial/AguasNegras/20S.jpg";
import submersibleproduct7 from "../../../images/productsImages/submersibleImages/products/Industrial/AguasNegras/WSEFFLUENT.jpg";
import submersibleproduct8 from "../../../images/productsImages/submersibleImages/products/Industrial/AltaEficiencia/AltaEficiencia.png";
import submersibleproduct9 from "../../../images/productsImages/submersibleImages/products/Industrial/BombasSumergibles/FE/SSI.jpg";
import submersibleproduct10 from "../../../images/productsImages/submersibleImages/products/Industrial/BombasSumergibles/FE/STS.jpg";
import submersibleproduct11 from "../../../images/productsImages/submersibleImages/products/Industrial/BombasSumergibles/PEARL/PWS.jpg";
import submersibleproduct12 from "../../../images/productsImages/submersibleImages/products/Industrial/MotoresSumergibles/NEMA6FOTO.jpg";
import submersibleproduct13 from "../../../images/productsImages/submersibleImages/products/Industrial/MotoresSumergibles/Nema8.jpg";
import submersibleproduct14 from "../../../images/productsImages/submersibleImages/products/Industrial/MotoresSumergibles/Brochure-Motores-Rebobinables-01.png";
import submersibleproduct15 from "../../../images/productsImages/submersibleImages/products/Industrial/MotoresSumergibles/6MRCP-MRSP.png";
import submersibleproduct16 from "../../../images/productsImages/submersibleImages/products/Industrial/MotoresSumergibles/7MRCP-01.png";
import submersibleproduct17 from "../../../images/productsImages/submersibleImages/products/Industrial/MotoresSumergibles/8MRCP-MRSP-01.png";
import pdf1 from "../../../pdf/submersible/industrial/os.pdf";
import pdf2 from "../../../pdf/submersible/industrial/OSC.pdf";
import pdf3 from "../../../pdf/submersible/industrial/osg.pdf";
import pdf4 from "../../../pdf/submersible/industrial/FWS.pdf";
import pdf5 from "../../../pdf/submersible/industrial/20e.pdf";
import pdf6 from "../../../pdf/submersible/industrial/20s.pdf";
import pdf7 from "../../../pdf/submersible/industrial/ws.pdf";
import pdf8 from "../../../pdf/submersible/industrial/AltaEficiencia.pdf";
import pdf9 from "../../../pdf/submersible/industrial/SSI.pdf";
import pdf10 from "../../../pdf/submersible/industrial/STS.pdf";
import pdf11 from "../../../pdf/submersible/industrial/PWS.pdf";
import pdf12 from "../../../pdf/submersible/industrial/NEMA6.pdf";
import pdf13 from "../../../pdf/submersible/industrial/NEMA8.pdf";
import pdf14 from "../../../pdf/submersible/industrial/Rebobinables.pdf";
import pdf15 from "../../../pdf/submersible/industrial/6MRCP-MRSP.pdf";
import pdf16 from "../../../pdf/submersible/industrial/7MRCP.pdf";
import pdf17 from "../../../pdf/submersible/industrial/8MRCP-MRSP.pdf";

const fe = "Franklin Electric";
const submersibleIndustrialProductList = [
  {
    _id: "subcatindagubgp001a",
    image: submersibleproduct1,
    title: "Bomba sumergible aguas negras OS",
    code: "BGP-OS",
    category: "Aguas Negras",
    brand: "BGP",
    subcategory: "BGP",
    downLoad: pdf1,
  },
  {
    _id: "subcatindagubgp001b",
    image: submersibleproduct2,
    title: "Bomba sumergible aguas negras OSC",
    code: "BGP-OSC",
    category: "Aguas Negras",
    brand: "BGP",
    subcategory: "BGP",
    downLoad: pdf2,
  },
  {
    _id: "subcatindagubgp001c",
    image: submersibleproduct3,
    title: "Bomba sumergible aguas negras OSG",
    code: "BGP-OSG",
    category: "Aguas Negras",
    brand: "BGP",
    subcategory: "BGP",
    downLoad: pdf3,
  },
  {
    _id: "subcatindagufe001a",
    image: submersibleproduct4,
    title: "Bombas sumergibles aguas negras serie FWS",
    code: "FPS",
    category: "Aguas Negras",
    brand: fe,
    subcategory: fe,
    downLoad: pdf4,
  },
  {
    _id: "subcatindaguLG001a",
    image: submersibleproduct5,
    title: "Bombas sumergibles aguas negras serie 20E",
    code: "20E",
    category: "Aguas Negras",
    brand: "Little Giant",
    subcategory: "Little Giant",
    downLoad: pdf5,
  },
  {
    _id: "subcatindaguLG001b",
    image: submersibleproduct6,
    title: "Bombas sumergibles aguas negras serie 20S",
    code: "20S",
    category: "Aguas Negras",
    brand: "Little Giant",
    subcategory: "Little Giant",
    downLoad: pdf6,
  },
  {
    _id: "subcatindaguLG001c",
    image: submersibleproduct7,
    title: "Bombas sumergibles aguas negras serie WS",
    code: "WS",
    category: "Aguas Negras",
    brand: "Little Giant",
    subcategory: "Little Giant",
    downLoad: pdf7,
  },
  {
    _id: "subcatindalt001a",
    image: submersibleproduct8,
    title: "Sistema de bombeo de alta eficiencia",
    code: "",
    category: "Alta Eficiencia",
    brand: fe,
    subcategory: fe,
    downLoad: pdf8,
  },
  {
    _id: "subcatindbomFE001a",
    image: submersibleproduct9,
    title: "Bomba de acero inoxidable serie SSI",
    code: "FE-SSI",
    category: "Bombas Sumergibles",
    brand: fe,
    subcategory: fe,
    downLoad: pdf9,
  },
  {
    _id: "subcatindbomFE001b",
    image: submersibleproduct10,
    title: "Bombas sumergibles turbina serie STS",
    code: "FE-STS",
    category: "Bombas Sumergibles",
    brand: fe,
    subcategory: fe,
    downLoad: pdf10,
  },
  {
    _id: "subcatindbompearl001a",
    image: submersibleproduct11,
    title: "Bombas sumergibles de acero inoxidable PWS",
    code: "",
    category: "Bombas Sumergibles",
    brand: "Pearl",
    subcategory: "Pearl",
    downLoad: pdf11,
  },
  {
    _id: "subcatindmotFEl001a",
    image: submersibleproduct12,
    title: `Motor Sand Fighter de 6"`,
    code: "",
    category: "Motores Sumergibles",
    brand: fe,
    subcategory: fe,
    downLoad: pdf12,
  },
  {
    _id: "subcatindmotFEl001b",
    image: submersibleproduct13,
    title: `Motores sumergibles encapsulados de 8"`,
    code: "",
    category: "Motores Sumergibles",
    brand: fe,
    subcategory: fe,
    downLoad: pdf13,
  },
  {
    _id: "subcatindmotFEl001c",
    image: submersibleproduct14,
    title: `Motores sumergibles rebobinables de 8", 10" y 12"`,
    code: "",
    category: "Motores Sumergibles",
    brand: fe,
    subcategory: fe,
    downLoad: pdf14,
  },
  {
    _id: "subcatindmotPEARLl001a",
    image: submersibleproduct15,
    title: `Motores sumergibles rebobinables 6MRCP-6MRSP”`,
    code: "6MRCP-6MRSP",
    category: "Motores Sumergibles",
    brand: "Pearl",
    subcategory: "Pearl",
    downLoad: pdf15,
  },
  {
    _id: "subcatindmotPEARLl001b",
    image: submersibleproduct16,
    title: `Motores sumergibles rebobinables 7MRCP`,
    code: "7MRCP",
    category: "Motores Sumergibles",
    brand: "Pearl",
    subcategory: "Pearl",
    downLoad: pdf16,
  },
  {
    _id: "subcatindmotPEARLl001c",
    image: submersibleproduct17,
    title: `Motores sumergibles rebobinables 8MRCP-8MRSP`,
    code: "8MRCP-8MRSP",
    category: "Motores Sumergibles",
    brand: "Pearl",
    subcategory: "Pearl",
    downLoad: pdf17,
  },
];

export default submersibleIndustrialProductList;
