import React from "react";
import { BsFacebook } from "react-icons/bs";
import classes from "./socialMedia.module.css";

const FacebookButton = () => {
  return (
    <div className={classes.facebook}>
      <a href="https://www.facebook.com/alrotek">
        <BsFacebook color="white" size="45" />
      </a>
    </div>
  );
};
export default FacebookButton;
