import poolproduct1 from "../../../images/productsImages/poolImages/products/filter/hidrotermal/BFSERIESIMAGE.png";
import poolproduct2 from "../../../images/productsImages/poolImages/products/filter/hidrotermal/PGSERIESIMAGE.png";
import poolproduct3 from "../../../images/productsImages/poolImages/products/filter/hidrotermal/VSERIESIMAGE.png";
import poolproduct4 from "../../../images/productsImages/poolImages/products/filter/pentair/CLEAN&CLEAR.png";
import poolproduct5 from "../../../images/productsImages/poolImages/products/filter/pentair/SANDDOLAR.jpg";
import poolproduct6 from "../../../images/productsImages/poolImages/products/filter/pentair/TAGELUS.jpg";
import poolproduct7 from "../../../images/productsImages/poolImages/products/filter/pentair/TRITON.jpg";
import pdf1 from "../../../pdf/pool/filter/Filtro-BFV.pdf";
import pdf2 from "../../../pdf/pool/filter/HT-PG.pdf";
import pdf3 from "../../../pdf/pool/filter/VSERIES.pdf";
import pdf4 from "../../../pdf/pool/filter/CLEAN&CLEAR.pdf";
import pdf5 from "../../../pdf/pool/filter/SANDDOLAR.pdf";
import pdf6 from "../../../pdf/pool/filter/TAGELUS.pdf";
import pdf7 from "../../../pdf/pool/filter/Triton_II_Fiberglass.pdf";

const poolFilterProductList = [
  {
    _id: "poocatfilhidro001a",
    image: poolproduct1,
    title: "Filtro serie BFV",
    code: "HT-BFV",
    category: "Hidrotermal",
    brand: "Hidrotermal",
    subcategory: "HT-BFV",
    downLoad: pdf1,
  },
  {
    _id: "poocatfilhidro002a",
    image: poolproduct2,
    title: "Filtro serie PG ",
    code: "HT-PG",
    category: "Hidrotermal",
    brand: "Hidrotermal",
    subcategory: "HT-PG",
    downLoad: pdf2,
  },
  {
    _id: "poocatfilhidro003a",
    image: poolproduct3,
    title: "Filtro serie V",
    code: "HT-V",
    category: "Hidrotermal",
    brand: "Hidrotermal",
    subcategory: "HT-V",
    downLoad: pdf3,
  },
  {
    _id: "poocatfilpent001a",
    image: poolproduct4,
    title: "Filtro de cartucho Clean & Clear",
    code: "",
    category: "Pentair",
    brand: "Pentair",
    subcategory: "Clean & Clear",
    downLoad: pdf4,
  },
  {
    _id: "poocatfilpent002a",
    image: poolproduct5,
    title: "Filtro Triton plus",
    code: "",
    category: "Pentair",
    brand: "Pentair",
    subcategory: "Sand Dollar",
    downLoad: pdf5,
  },
  {
    _id: "poocatfilpent003a",
    image: poolproduct6,
    title: "Filtro Tagelus",
    code: "",
    category: "Pentair",
    brand: "Pentair",
    subcategory: "Tagelus",
    downLoad: pdf6,
  },
  {
    _id: "poocatfilpent004a",
    image: poolproduct7,
    title: "Filtro Triton II",
    code: "",
    category: "Pentair",
    brand: "Pentair",
    subcategory: "Triton",
    downLoad: pdf7,
  },
];

export default poolFilterProductList;
