import React, { useState } from "react";
import { poolList } from "../../../Lists/products/poolList";
import Category from "../../../components/UI/Category";
import classes from "./poolCleaning.module.css";
import Subcategory from "../../../components/Subcategorymenu";
import { useParams } from "react-router-dom";
import poolcleaningProductsList from "../../../Lists/products/poolProducts/poolCleaningList";
import poolEmbedProductList from "../../../Lists/products/poolProducts/poolEmbedList";
import poolBombProductList from "../../../Lists/products/poolProducts/poolBombList";
import poolChemicalProductList from "../../../Lists/products/poolProducts/poolChemicalList";
import poolDecorationProductList from "./../../../Lists/products/poolProducts/poolDecorationList";
import poolDisinfectionProductList from "./../../../Lists/products/poolProducts/poolDisinfectionList";
import poolFilterProductList from "./../../../Lists/products/poolProducts/poolFilterList";
import poolHeatingProductList from "./../../../Lists/products/poolProducts/poolHeatingList";
import poolLightProductList from "./../../../Lists/products/poolProducts/poolLightList";
import hidrotermalLogo from "../../../images/brands/brands_hidrotermal.svg";
import pentairLogo from "../../../images/brands/brrands_PENTAIR.svg";

const PoolProducts = () => {
  const newArray = [];

  const params = useParams();

  const listCategory = poolList.find(
    (category) => category.path === params.poolId
  );
  const chamicals = "quimicos" === params.poolId;

  const hidrotermalBrand = {
    image: hidrotermalLogo,
    alt: "Hidrotermal",
  };
  const PentairBrand = {
    image: pentairLogo,
    alt: "Pentair",
  };

  const showSubcategory = () => {
    if ("quimicos" === params.poolId) {
      return;
    } else
      return (
        <section>
          <Subcategory
            onListChange={onListChange}
            onsubListChange={onsubListChange}
            categoryList={listCategory.list}
          ></Subcategory>
        </section>
      );
  };

  const findListHandle = (path) => {
    if (path === "limpieza") {
      return poolcleaningProductsList;
    }
    if (path === "empotrar") {
      return poolEmbedProductList;
    }
    if (path === "decoracion") {
      return poolDecorationProductList;
    }
    if (path === "bombas") {
      return poolBombProductList;
    }
    if (path === "filtro") {
      return poolFilterProductList;
    }
    if (path === "lucespiscinas") {
      return poolLightProductList;
    }
    if (path === "quimicos") {
      return poolChemicalProductList;
    }
    if (path === "calefaccion") {
      return poolHeatingProductList;
    }
    if (path === "desinfeccion") {
      return poolDisinfectionProductList;
    }
  };
  const findBrandHandle = (path) => {
    if (path === "empotrar") {
      return hidrotermalBrand;
    }
    if (path === "decoracion") {
      return hidrotermalBrand;
    } else {
      return "none";
    }
  };
  const originalList = findListHandle(listCategory.path);

  const [displayedList, setdisplayedList] = useState(
    findListHandle(listCategory.path)
  );
  const [displayedBrand, setdisplayedBrand] = useState(
    findBrandHandle(listCategory.path)
  );

  const onListChange = (category) => {
    newArray.length = 0;
    originalList.map((element) => sort(element, category));
    setdisplayedList(newArray);

    setdisplayedBrand(findBrand(category));
  };

  const sort = (element, category) => {
    if (element.category === category) {
      newArray.push(element);
    }
  };
  const findBrand = (category) => {
    if (category === "Hidrotermal") {
      return hidrotermalBrand;
    } else return PentairBrand;
  };

  const onsubListChange = (category, secondCategory) => {
    newArray.length = 0;
    originalList.map((element) =>
      secondsort(element, category, secondCategory)
    );

    setdisplayedList(newArray);
  };
  const secondsort = (element, category, secondCategory) => {
    if (
      element.category === category &&
      element.subcategory === secondCategory
    ) {
      newArray.push(element);
    }
  };

  return (
    <div className={classes.showProducts}>
      {showSubcategory()}
      <section
        className={` ${chamicals ? classes.onlyProducts : classes.products}`}
      >
        <Category
          brand={displayedBrand}
          title={listCategory.title}
          list={displayedList}
        ></Category>
      </section>
    </div>
  );
};

export default PoolProducts;
