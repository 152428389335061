import number1 from "../../images/galleryImages/control/1.jpg";
import number2 from "../../images/galleryImages/control/2.jpg";
import number3 from "../../images/galleryImages/control/3.jpg";
import number4 from "../../images/galleryImages/control/4.jpg";
import number5 from "../../images/galleryImages/control/5.jpg";
import number6 from "../../images/galleryImages/control/6.jpg";
import number7 from "../../images/galleryImages/control/7.jpg";
import number8 from "../../images/galleryImages/control/8.jpg";
import number9 from "../../images/galleryImages/control/9.jpg";
import number10 from "../../images/galleryImages/control/10.jpg";
import number11 from "../../images/galleryImages/control/11.jpg";
import number12 from "../../images/galleryImages/control/12.jpg";

const controlSliderList = [
  {
    _id: "control 001",
    image: number1,
    title: "Título de la imagen de Control y Potencia",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "control 002",
    image: number2,
    title: "Título de la imagen de Control y Potencia",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "control 003",
    image: number3,
    title: "Título de la imagen de Control y Potencia",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "control 004",
    image: number4,
    title: "Título de la imagen de Control y Potencia",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "control 005",
    image: number5,
    title: "Título de la imagen de Control y Potencia",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "control 006",
    image: number6,
    title: "Título de la imagen de Control y Potencia",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "control 007",
    image: number7,
    title: "Título de la imagen de Control y Potencia",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "control 008",
    image: number8,
    title: "Título de la imagen de Control y Potencia",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "control 009",
    image: number9,
    title: "Título de la imagen de Control y Potencia",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "control 010",
    image: number10,
    title: "Título de la imagen de Control y Potencia",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "control 011",
    image: number11,
    title: "Título de la imagen de Control y Potencia",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "control 012",
    image: number12,
    title: "Título de la imagen de Control y Potencia",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
];
export default controlSliderList;
