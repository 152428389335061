import React from "react";
import error404 from "../images/404.gif";
import classes from "./NotFound.module.css";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className={classes.notFoundPage}>
      <img className={classes.notFoundGif} src={error404} alt="Error 404"></img>
      <h1 className={classes.notFoundTitle}>Página no encontrada</h1>
      <p className={classes.notFoundText}>
        El error 404 pudo haber ocurrido debido a las siguientes razones: <br />
        •Hay un error en la URL que escribiste en el navegador web, verifique si
        la URL es la correcta e inténtalo de nuevo. <br />
        •La página que estás buscando cambió de ubicación o fue eliminada.
        <br />
        Para volver a tu página de inicio haz clic en el botón, o puedes
        intentar buscar el contenido que deseas desde la barra de navegación.
      </p>
      <Link type="button" className="btn btn-outline-primary btn-lg" to="/home">
        Home
      </Link>
    </div>
  );
};

export default NotFound;
