import poolproduct1 from "../../../images/productsImages/poolImages/products/decoration/waterfalldeco.jpg";
import poolproduct2 from "../../../images/productsImages/poolImages/products/decoration/ESCALERASERIESF.jpg";
import poolproduct3 from "../../../images/productsImages/poolImages/products/decoration/WATERDESCENT.png";
import pdf1 from "../../../pdf/pool/decoration/WATERDESCENT.pdf";
import pdf2 from "../../../pdf/pool/decoration/HIDRO-WF0972.pdf";

const poolDecorationProductList = [
  {
    _id: "poocatdecohidro001a",
    image: poolproduct1,
    title: "Cascada de piscina",
    code: "HIDRO-WF0972",
    category: "Hidrotermal",
    brand: "Hidrotermal",
    subcategory: "Cascada Serie WF",
    downLoad: pdf2,
  },
  {
    _id: "poocatdecohidro002a",
    image: poolproduct2,
    title: "Escalera antideslizante",
    code: "SERIE SF",
    category: "Hidrotermal",
    brand: "Hidrotermal",
    subcategory: "Escalera",
    downLoad: "none",
  },
  {
    _id: "poocatdecohidro003a",
    image: poolproduct3,
    title: "Water descent",
    code: "WD SERIES",
    category: "Hidrotermal",
    brand: "Hidrotermal",
    subcategory: "Cascada",
    downLoad: pdf1,
  },
];

export default poolDecorationProductList;
