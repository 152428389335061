import React from "react";
import { useRef } from "react";
import Footer from "../footer/footer";
import { NavLink } from "react-router-dom";
import ALROTEKlogo from "../images/logoALROTEK-01.svg";
import classes from "./Layout.module.css";
import "./styles.css";
import WhatsAppButton from "./../components/WhatsApp";

const Layout = (props) => {
  const navButton = useRef(null);
  const linksContainerRef = useRef(null);

  function collapseNav() {
    navButton.current.classList.add("collapsed");
    linksContainerRef.current.classList.remove("show");
  }

  return (
    <>
      <header>
        <nav className="navbar fixed-top navbar-expand-lg navbar-dark gradient-custom fs-5 py-4">
          <div className="container-fluid">
            <img
              className="navbar-brand logoCellphone"
              src={ALROTEKlogo}
              alt="alrotek de centroamerica"
            />
            <button
              ref={navButton}
              className="navbar-toggler ms-auto"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="true"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              ref={linksContainerRef}
              className="collapse navbar-collapse"
              id="navbarNavDropdown"
              data-toggle="collapse"
              data-target=".navbar-collapse"
            >
              <ul className="navbar-nav mx-auto">
                <li className="nav-item active custom-toggler">
                  <NavLink
                    onClick={collapseNav}
                    className="nav-link"
                    to="/home"
                  >
                    <img
                      className={classes.logo}
                      src={ALROTEKlogo}
                      alt="alrotek de centroamerica"
                    />
                    <p className={classes.homeOption}>Home</p>
                  </NavLink>
                </li>
                <li className="nav-item dropdown custom-toggler">
                  <NavLink
                    className="nav-link dropdown-toggle"
                    to="#"
                    id="navbarDropdownMenuLink"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Productos
                  </NavLink>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <NavLink
                      onClick={collapseNav}
                      className="dropdown-item"
                      to="/productos/piscina"
                    >
                      Equipos y accesorios para piscina
                    </NavLink>
                    <NavLink
                      onClick={collapseNav}
                      className="dropdown-item"
                      to="/productos/presionconstante"
                    >
                      Sistemas de presión constante
                    </NavLink>
                    <NavLink
                      onClick={collapseNav}
                      className="dropdown-item"
                      to="/productos/contraincendio"
                    >
                      Sistemas contra incendio
                    </NavLink>
                    <NavLink
                      onClick={collapseNav}
                      className="dropdown-item"
                      to="/productos/sistemashidroneumaticos"
                    >
                      Sistemas de presión hidroneumáticos
                    </NavLink>
                    <NavLink
                      onClick={collapseNav}
                      className="dropdown-item"
                      to="/productos/bombassumergibles"
                    >
                      Equipos de bombeo sumergibles
                    </NavLink>
                    <NavLink
                      onClick={collapseNav}
                      className="dropdown-item"
                      to="/productos/bombassuperficie"
                    >
                      Equipos de bombeo de superficie
                    </NavLink>
                    <NavLink
                      onClick={collapseNav}
                      className="dropdown-item"
                      to="/productos/tanqueshidroneumaticos"
                    >
                      Tanques hidroneumáticos
                    </NavLink>
                    <NavLink
                      onClick={collapseNav}
                      className="dropdown-item"
                      to="/productos/tablerosdecontrolyproteccion"
                    >
                      Tableros de control y protección
                    </NavLink>
                  </div>
                </li>
                <li className="nav-item dropdown custom-toggler">
                  <NavLink
                    className="nav-link dropdown-toggle"
                    to="#"
                    id="dropdownMenuReference"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    data-reference="parent"
                  >
                    Servicios
                  </NavLink>
                  <div
                    className="dropdown-menu dropdown-menu-right"
                    aria-labelledby="dropdownMenuReference"
                  >
                    <NavLink
                      onClick={collapseNav}
                      className="dropdown-item"
                      to="/servicios/alquilerequipo"
                    >
                      Alquiler de equipos
                    </NavLink>
                    <NavLink
                      onClick={collapseNav}
                      className="dropdown-item"
                      to="/servicios/asesoramiento"
                    >
                      Asesoramiento y venta
                    </NavLink>
                    <NavLink
                      onClick={collapseNav}
                      className="dropdown-item"
                      to="/servicios/ingenieria"
                    >
                      Ingeniería y diseño
                    </NavLink>
                    <NavLink
                      onClick={collapseNav}
                      className="dropdown-item"
                      to="/servicios/mantenimiento"
                    >
                      Mantenimientos
                    </NavLink>
                    <NavLink
                      onClick={collapseNav}
                      className="dropdown-item"
                      to="/servicios/revisionpozos"
                    >
                      Revisión de pozos
                    </NavLink>
                    <NavLink
                      onClick={collapseNav}
                      className="dropdown-item"
                      to="/servicios/taller"
                    >
                      Taller de servicio
                    </NavLink>
                  </div>
                </li>
                <li className="nav-item active custom-toggler">
                  <NavLink
                    onClick={collapseNav}
                    className="nav-link"
                    to="/galeria"
                  >
                    Galería
                  </NavLink>
                </li>
                <li className="nav-item active custom-toggler">
                  <NavLink
                    onClick={collapseNav}
                    className="nav-link"
                    to="/marcas"
                  >
                    Marcas
                  </NavLink>
                </li>
                <li className="nav-item active custom-toggler">
                  <NavLink
                    onClick={collapseNav}
                    className="nav-link"
                    to="/contactenos"
                  >
                    Contacto
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <main>{props.children}</main>
      <WhatsAppButton></WhatsAppButton>
      <Footer></Footer>
    </>
  );
};

export default Layout;
