import React from "react";
import classes from "./Contact.module.css";
import ContactForm from "../components/ContactForm";
import Background from "../images/fondo.jpg";
import icon1 from "../images/contactImages/icon1.svg";
import icon2 from "../images/contactImages/icon2.svg";
import icon3 from "../images/contactImages/icon3.svg";
import icon4 from "../images/contactImages/icon4.svg";
import { Helmet } from "react-helmet";

const Contact = () => {
  const sectionStyle = {
    width: "100%",
    backgroundImage: `url(${Background})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <>
      <Helmet>
        <title>Home</title>
        <meta
          name="Contáctenos"
          content="contactenos por mensaje correo numero de telefono ubicacion palmares alajuela costa rica"
        />
      </Helmet>
      <section className={classes.background} style={sectionStyle}>
        <div className={classes.contact}>
          <h2>Contáctenos</h2>
          <ContactForm></ContactForm>
        </div>
      </section>
      <div className={classes.visit}>
        <div>
          <h1>Visítanos</h1>
          <p>
            Nos gustaría recibirlo y ofrecer nuestros servicios en sus
            proyectos.
          </p>
          <div className={classes.infoContact}>
            <div className={classes.row}>
              <div className={classes.infobox}>
                <img
                  className={classes.iconContact}
                  src={icon2}
                  alt="Ubicacion"
                ></img>
                <h5>Dirección</h5>
                <p>
                  25 metros norte de la <br></br>intersección de Palmares.
                </p>
              </div>
              <div className={classes.infobox}>
                <img
                  className={classes.iconContact}
                  src={icon1}
                  alt="Horario de Atencion"
                ></img>
                <h5>Horario</h5>
                <p>
                  Lunes a viernes <br></br> 7:30 a. m. 6:00 p. m.
                </p>
              </div>
            </div>
            <div className={classes.row}>
              <div className={classes.infobox}>
                <img
                  className={classes.iconContact}
                  src={icon3}
                  alt="Atencion telefonica"
                ></img>
                <h5>Teléfono</h5>
                <p>+506 2452 1046 </p>
              </div>
              <div className={classes.infobox}>
                <img
                  className={classes.iconContact}
                  src={icon4}
                  alt="Correo electronico"
                ></img>
                <h5>Correo</h5>
                <p>info@alrotek.com</p>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.map}>
          <iframe
            height="400"
            width="400"
            title="Iframe Example"
            className="gmap_iframe"
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
            src="https://maps.google.com/maps?width=1048&amp;height=853&amp;hl=en&amp;q=costa rica, palmares, ALROTEK&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          ></iframe>
        </div>
      </div>
    </>
  );
};
export default Contact;
