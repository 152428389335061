import React from "react";
import classes from "./EmployeeCard.module.css";
import { AiOutlineMessage } from "react-icons/ai";

const EmployeeCard = (props) => {
  const renderMessageIcon = () => {
    if (props.contact === true)
      return (
        <a href={props.link}>
          <AiOutlineMessage className={classes.icon} />
        </a>
      );
  };
  return (
    <div className={`${classes.employeeCard}`}>
      <div>
        <img
          src={props.photo}
          className={classes.employeePhoto}
          alt={props.name}
        ></img>
      </div>
      <div className={classes.personalInfo}>
        <h5>{props.name}</h5>
        <div className={classes.contactgroup}>
          <p>{props.number}</p>
          {renderMessageIcon()}
        </div>
        <p>{props.email}</p>
      </div>
    </div>
  );
};

export default EmployeeCard;
