import React from "react";
import ProductMenu from "../../components/UI/Productmenu";
import tankList from "../../Lists/products/tankList";
import classes from "./General.module.css";
import { Helmet } from "react-helmet";

const Tank = () => {
  const pageTitle = "Tanques Hidroneumáticos";
  return (
    <div className={classes.productMenuPage}>
      <Helmet>
        <title>{pageTitle}</title>
        <meta
          name={pageTitle}
          content="Contamos con tanques hidroneumáticos diferentes tamaños, formas, materiales, para uso en ambientes
          corrosivos y altas presiones"
        />
      </Helmet>
      <div className={classes.productMainInfo}>
        <h1>{pageTitle}</h1>
        <p>
          Contamos con tanques hidroneumáticos en gran variedad en tamaños,
          formas y materiales de construcción, algunos para uso en ambientes
          corrosivos y también para altas presiones.
        </p>
      </div>
      <ProductMenu list={tankList}></ProductMenu>
    </div>
  );
};

export default Tank;
