import React, { useState, useEffect, useRef } from "react";
import classes from "./ContactForm.module.css";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";

const ContactForm = () => {
  const form = useRef();

  const [enteredName, setEnteredName] = useState("");
  const [enteredLastname, setEnteredLastname] = useState("");
  const [enteredEmail, setEnteredEmail] = useState("");
  const [enteredNumber, setEnteredNumber] = useState("");
  const [enteredMessage, setEnteredMessage] = useState("");

  const [isValid, setIsValid] = useState(false);

  const [nameIsValid, setNameIsValid] = useState();
  const [lastnameIsValid, setLastnameIsValid] = useState();
  const [emailIsValid, setEmailIsValid] = useState();
  const [numberIsValid, setNumberilIsValid] = useState();
  const [messageIsValid, setMessageIsValid] = useState();
  //name
  const nameChangeHandler = (event) => {
    setEnteredName(event.target.value);
  };
  const validateNameHandler = () => {
    setNameIsValid(enteredName.trim().length > 2);
  };
  //lastname
  const lastnameChangeHandler = (event) => {
    setEnteredLastname(event.target.value);
  };
  const validateLastnameHandler = () => {
    setLastnameIsValid(enteredName.trim().length > 2);
  };
  //email
  const emailChangeHandler = (event) => {
    setEnteredEmail(event.target.value);
  };
  const validateEmailHandler = () => {
    setEmailIsValid(enteredEmail.trim().length > 3);
  };
  //cellpone
  const numberChangeHandler = (event) => {
    setEnteredNumber(event.target.value);
  };
  const validateNumberHandler = () => {
    setNumberilIsValid(enteredNumber.trim().length > 7);
  };
  //message
  const messageChangeHandler = (event) => {
    setEnteredMessage(event.target.value);
  };
  const validateMessagetHandler = () => {
    setMessageIsValid(enteredMessage.trim().length > 7);
  };

  const submitHandler = (event) => {
    event.preventDefault();

    emailjs
      .sendForm(
        "service_dlipvmj",
        "template_0zp3aj2",
        form.current,
        "f618BtiyANFf_UNrj"
      )
      .then(
        (result) => {
          Swal.fire("Tu mensaje se ha enviado satisfactoriamente.");
        },
        (error) => {
          Swal.fire("Algo salio mal, intenta envial el mensaje de nuevo.");
        }
      );

    setEnteredName("");
    setEnteredLastname("");
    setEnteredEmail("");
    setEnteredNumber("");
    setEnteredMessage("");
  };

  useEffect(() => {
    const identifier = setTimeout(() => {
      setIsValid(
        enteredName.trim().length > 2 &&
          enteredLastname.trim().length > 2 &&
          enteredEmail.trim().length > 3 &&
          enteredNumber.trim().length > 3 &&
          enteredMessage.trim().length > 7
      );
    }, 500);
    return () => {
      clearTimeout(identifier);
    };
  }, [
    enteredName,
    enteredLastname,
    enteredEmail,
    enteredNumber,
    enteredMessage,
  ]);

  return (
    <form ref={form} onSubmit={submitHandler}>
      <div className={classes.controls}>
        <div>
          <div
            className={`${
              nameIsValid === false ? classes.invalid : classes.valid
            }`}
          >
            <label>Nombre</label>
            <input
              type="text"
              name="user_name"
              value={enteredName}
              onChange={nameChangeHandler}
              onBlur={validateNameHandler}
            ></input>
            <p>El nombre debe tener más de tres caracteres</p>
          </div>
          <div
            className={`${
              lastnameIsValid === false ? classes.invalid : classes.valid
            }`}
          >
            <label>Apellidos</label>
            <input
              type="text"
              name="user_name"
              value={enteredLastname}
              onChange={lastnameChangeHandler}
              onBlur={validateLastnameHandler}
            ></input>
            <p>El apellido debe tener más de tres caracteres</p>
          </div>
        </div>
        <div
          className={`${
            emailIsValid === false ? classes.invalid : classes.valid
          }`}
        >
          <label>Correo electrónico</label>
          <input
            type="text"
            name="user_email"
            value={enteredEmail}
            onChange={emailChangeHandler}
            onBlur={validateEmailHandler}
          ></input>
          <p>someone@example.com</p>
        </div>
        <div
          className={`${
            numberIsValid === false ? classes.invalid : classes.valid
          }`}
        >
          <label>Teléfono</label>
          <input
            type="number"
            name="message"
            value={enteredNumber}
            onChange={numberChangeHandler}
            onBlur={validateNumberHandler}
          ></input>
          <p>El número de teléfono debe tener al menos 8 dígitos</p>
        </div>
        <label htmlFor="category">
          ¿A cuál categoría se refiere su consulta?
        </label>
        <select
          className={classes.select}
          name="message"
          id="category"
          form="categoryform"
        >
          <option value="industrial">Industrial</option>
          <option value="mantenimiento">Mantenimiento</option>
          <option value="piscina">Piscina</option>
          <option value="Potencia">Potencia, control, monitoreo</option>
          <option value="Residencial">Residencial</option>
          <option value="otros">Otros</option>
        </select>
        <div
          className={`${
            messageIsValid === false ? classes.invalid : classes.valid
          }`}
        >
          <label>Mensaje</label>
          <input
            className={classes.message}
            type="text"
            name="message"
            value={enteredMessage}
            onChange={messageChangeHandler}
            onBlur={validateMessagetHandler}
          ></input>
          <p>El apellido debe tener al menos siete caracteres</p>
        </div>
        <div className={classes.action}>
          <button
            className={classes.button}
            type="submit"
            colortype="lighter"
            width="big"
            disabled={!isValid}
          >
            Enviar
          </button>
        </div>
      </div>
    </form>
  );
};
export default ContactForm;
