import lpz from "../../images/productsImages/hydroneumaticImages/LPZ.jpg";
import ljp from "../../images/productsImages/hydroneumaticImages/SERIELJP.jpg";
import pg from "../../images/productsImages/hydroneumaticImages/SERIEPG.jpg";
import pdf1 from "../../pdf/hydroneumatic/Serie-LPZ.pdf";
import pdf2 from "../../pdf/hydroneumatic/Serie-LJP.pdf";
import pdf3 from "../../pdf/hydroneumatic/Serie-PG.pdf";

const hydroneumaticList = [
  {
    _id: "hyd001",
    image: lpz,
    title: "Sistema hidroneumático serie LPZ",
    code: "",
    category: "Little Giant",
    brand: "Little Giant",
    downLoad: pdf1,
  },
  {
    _id: "hyd002",
    image: ljp,
    title: "Sistema hidroneumático serie LJP",
    code: "",
    category: "Little Giant",
    brand: "Little Giant",
    downLoad: pdf2,
  },
  {
    _id: "hyd003",
    image: pg,
    title: "Sistema hidroneumático serie PG",
    code: "",
    category: "Little Giant",
    brand: "Little Giant",
    downLoad: pdf3,
  },
];

export default hydroneumaticList;
