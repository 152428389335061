import poolproduct1 from "../../../images/productsImages/poolImages/products/embed/boquilla3.png";
import poolproduct2 from "../../../images/productsImages/poolImages/products/embed/boquilla4.png";
import poolproduct3 from "../../../images/productsImages/poolImages/products/embed/boquilla.png";
import poolproduct4 from "../../../images/productsImages/poolImages/products/embed/jet.png";
import poolproduct5 from "../../../images/productsImages/poolImages/products/embed/boquilla2.png";
import poolproduct6 from "../../../images/productsImages/poolImages/products/embed/PF-1032Rejilla.jpg";
import poolproduct7 from "../../../images/productsImages/poolImages/products/embed/rejilla.png";
import poolproduct8 from "../../../images/productsImages/poolImages/products/embed/PF-001Skimmer.png";
import poolproduct9 from "../../../images/productsImages/poolImages/products/embed/PF-009-1Skimmer.png";
import poolproduct10 from "../../../images/productsImages/poolImages/products/embed/PF-1030Sumidero.jpg";
import poolproduct11 from "../../../images/productsImages/poolImages/products/embed/PF-2010CSumidero.png";
import poolproduct12 from "../../../images/productsImages/poolImages/products/embed/PF-2011Sumidero.png";

const poolEmbedProductList = [
  {
    _id: "poocatembhidro001a",
    image: poolproduct1,
    title: "Boquilla de retorno",
    code: "MHWF-01C",
    category: "Hidrotermal",
    brand: "Hidrotermal",
    subcategory: "Boquillas",
    downLoad: "none",
  },
  {
    _id: "poocatembhidro001b",
    image: poolproduct2,
    title: "Boquilla de rebalse",
    code: "PF-1020",
    category: "Hidrotermal",
    brand: "Hidrotermal",
    subcategory: "Boquillas",
    downLoad: "none",
  },
  {
    _id: "poocatembhidro001c",
    image: poolproduct3,
    title: `Boquilla de aspiración 1.5"`,
    code: "PF-1022",
    category: "Hidrotermal",
    brand: "Hidrotermal",
    subcategory: "Boquillas",
    downLoad: "none",
  },
  {
    _id: "poocatembhidro001d",
    image: poolproduct4,
    title: "Jet hidromasaje",
    code: "PF-1510 ",
    category: "Hidrotermal",
    brand: "Hidrotermal",
    subcategory: "Boquillas",
    downLoad: "none",
  },
  {
    _id: "poocatembhidro001e",
    image: poolproduct5,
    title: `Boquilla de aspiración 2"`,
    code: "PF-2831C",
    category: "Hidrotermal",
    brand: "Hidrotermal",
    subcategory: "Boquillas",
    downLoad: "none",
  },
  {
    _id: "poocatembhidro002a",
    image: poolproduct6,
    title: "Rejilla",
    code: "PF-1032",
    category: "Hidrotermal",
    brand: "Hidrotermal",
    subcategory: "Rejillas",
    downLoad: "none",
  },
  {
    _id: "poocatembhidro002b",
    image: poolproduct7,
    title: "Rejilla",
    code: "PF-1036",
    category: "Hidrotermal",
    brand: "Hidrotermal",
    subcategory: "Rejillas",
    downLoad: "none",
  },
  {
    _id: "poocatembhidro003a",
    image: poolproduct8,
    title: "Standar skimmer",
    code: "PF-001",
    category: "Hidrotermal",
    brand: "Hidrotermal",
    subcategory: "Skimmers",
    downLoad: "none",
  },
  {
    _id: "poocatembhidro003b",
    image: poolproduct9,
    title: "ABS Fiberglass pool & spa skimmer",
    code: "PF-009-1",
    category: "Hidrotermal",
    brand: "Hidrotermal",
    subcategory: "Skimmers",
    downLoad: "none",
  },
  {
    _id: "poocatembhidro004a",
    image: poolproduct10,
    title: "Sumidero",
    code: "PF-1030",
    category: "Hidrotermal",
    brand: "Hidrotermal",
    subcategory: "Sumideros",
    downLoad: "none",
  },
  {
    _id: "poocatembhidro004b",
    image: poolproduct11,
    title: "Sumidero",
    code: "PF-2010C",
    category: "Hidrotermal",
    brand: "Hidrotermal",
    subcategory: "Sumideros",
    downLoad: "none",
  },
  {
    _id: "poocatembhidro004c",
    image: poolproduct12,
    title: "Sumidero Antivortex",
    code: "PF-2011",
    category: "Hidrotermal",
    brand: "Hidrotermal",
    subcategory: "Sumideros",
    downLoad: "none",
  },
];

export default poolEmbedProductList;
