import React from "react";
import classes from "./Productcard.module.css";

const Productcard = (props) => {
  const showDownLoadButton = () => {
    if (props.productDownLoad === "none") {
      return;
    } else
      return (
        <button
          type="button"
          className="btn btn-primary"
          onClick={onButtonClick}
        >
          Download PDF
        </button>
      );
  };

  const onButtonClick = () => {
    fetch(props.productDownLoad).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = props.productDownLoad;
        alink.click();
      });
    });
  };

  return (
    <div className={`${classes.card} ${props.className}`}>
      <img
        src={props.productimg}
        alt={`${props.productitle} ${props.productBrand}`}
      ></img>
      <h5>{props.productitle}</h5>
      <h5>{props.productBrand}</h5>
      {showDownLoadButton()}
    </div>
  );
};

export default Productcard;
