import armstrong from "../images/brands/brands_armstrong.svg";
import flomatic from "../images/brands/brands_FLOMATIC.svg";
import grundfos from "../images/brands/brands_GRUNDFOS.svg";
import kalas from "../images/brands/brands_KALAS.svg";
import little from "../images/brands/brands_LITTLE.svg";
import wilo from "../images/brands/brands_WILO.svg";
import aquastrong from "../images/brands/brands_aquarong.svg";
import arvek from "../images/brands/brands_ARVEK.svg";
import bgp from "../images/brands/brands_BGP.svg";
import schneider from "../images/brands/brands_schneider.svg";
import pioneer from "../images/brands/brands_pioneer.png";
import ge from "../images/brands/ge.svg";
import pearl from "../images/brands/brands_PEARL.svg";
import pentair from "../images/brands/brrands_PENTAIR.svg";
import globalwater from "../images/brands/brands_GLOBAL.svg";
import hidrotermal from "../images/brands/brands_hidrotermal.svg";
import boshart from "../images/brands/boshart.svg";
import baldor from "../images/brands/abbbaldor.svg";

const brandsList = [
  {
    _id: "brand001",
    image: pearl,
    alt: "Pearl",
  },
  {
    _id: "brand002",
    image: pentair,
    alt: "Pentair",
  },
  {
    _id: "brand003",
    image: globalwater,
    alt: "Global Water Solutions",
  },
  {
    _id: "brand004",
    image: hidrotermal,
    alt: "Hidrotermal",
  },
  {
    _id: "brand005",
    image: kalas,
    alt: "Kalas",
  },
  {
    _id: "brand006",
    image: little,
    alt: "Little Giant",
  },
  {
    _id: "brand007",
    image: armstrong,
    alt: "Armstrong",
  },
  {
    _id: "brand008",
    image: bgp,
    alt: "bgp",
  },
  {
    _id: "brand009",
    image: aquastrong,
    alt: "Aquastrong watering the life",
  },
  {
    _id: "brand010",
    image: arvek,
    alt: "Arvek",
  },
  {
    _id: "brand011",
    image: flomatic,
    alt: "Flomatic valves",
  },
  {
    _id: "brand012",
    image: grundfos,
    alt: "Grundfos",
  },
  {
    _id: "brand013",
    image: schneider,
    alt: "Schneider Electric",
  },
  {
    _id: "brand014",
    image: wilo,
    alt: "Wilo",
  },
  {
    _id: "brand015",
    image: pioneer,
    alt: "Popnner Pump Performance through innovation",
  },
  {
    _id: "brand016",
    image: ge,
    alt: "ge industrial motors a wolong company",
  },
  {
    _id: "brand017",
    image: boshart,
    alt: "Boshart",
  },
  {
    _id: "brand018",
    image: baldor,
    alt: "Abb Baldor",
  },
];

export default brandsList;
