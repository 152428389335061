import React from "react";
import Cardmenu from "./Cardmenu";
import classes from "./Productmenu.module.css";
const Productmenu = (props) => {
  return (
    <section className={classes.menu}>
      {props.list.map((option) => (
        <Cardmenu
          id={option._id}
          key={option._id}
          className={classes.optionCard}
          image={option.image}
          title={option.title}
          link={option.link}
          icon={option.icon}
        ></Cardmenu>
      ))}
    </section>
  );
};

export default Productmenu;
