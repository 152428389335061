import poolproduct1 from "../../../images/productsImages/poolImages/products/bomb/hidrotermal/BPS.jpg";
import poolproduct2 from "../../../images/productsImages/poolImages/products/bomb/hidrotermal/Hidro-SP.jpg";
import poolproduct3 from "../../../images/productsImages/poolImages/products/bomb/hidrotermal/HML.png";
import poolproduct4 from "../../../images/productsImages/poolImages/products/bomb/hidrotermal/HMT.png";
import poolproduct5 from "../../../images/productsImages/poolImages/products/bomb/hidrotermal/SPRIMAGEN.png";
import poolproduct6 from "../../../images/productsImages/poolImages/products/bomb/pentair/Optiflo.jpg";
import poolproduct7 from "../../../images/productsImages/poolImages/products/bomb/pentair/SUPERFLO.jpg";
import poolproduct8 from "../../../images/productsImages/poolImages/products/bomb/pentair/Whisperflo.jpg";
import pdf1 from "../../../pdf/pool/bomb/BPS.pdf";
import pdf2 from "../../../pdf/pool/bomb/HidroSP.pdf";
import pdf3 from "../../../pdf/pool/bomb/pumpHMT.pdf";
import pdf4 from "../../../pdf/pool/bomb/HML.pdf";
import pdf5 from "../../../pdf/pool/bomb/SPR.pdf";
import pdf6 from "../../../pdf/pool/bomb/Optiflo.pdf";
import pdf7 from "../../../pdf/pool/bomb/SUPERFLO.pdf";
import pdf8 from "../../../pdf/pool/bomb/Whisperflo.pdf";

const poolBombProductList = [
  {
    _id: "poocatbomhidro001a",
    image: poolproduct1,
    title: "Bomba de piscina BPS",
    code: "BPS",
    category: "Hidrotermal",
    brand: "Hidrotermal",
    subcategory: "BPS",
    downLoad: pdf1,
  },
  {
    _id: "poocatbomhidro002a",
    image: poolproduct2,
    title: "Bomba de piscina SP",
    code: "HML",
    category: "Hidrotermal",
    brand: "Hidrotermal",
    subcategory: "SP",
    downLoad: pdf2,
  },
  {
    _id: "poocatbomhidro003a",
    image: poolproduct3,
    title: "Bomba de piscina HMT",
    code: "HMT",
    category: "Hidrotermal",
    brand: "Hidrotermal",
    subcategory: "HMT",
    downLoad: pdf3,
  },
  {
    _id: "poocatbomhidro004a",
    image: poolproduct4,
    title: "Bomba de piscina HML",
    code: "SP",
    category: "Hidrotermal",
    brand: "Hidrotermal",
    subcategory: "HML",
    downLoad: pdf4,
  },
  {
    _id: "poocatbomhidro005a",
    image: poolproduct5,
    title: "Bomba de piscina SPR",
    code: "SPR",
    category: "Hidrotermal",
    brand: "Hidrotermal",
    subcategory: "SPR",
    downLoad: pdf5,
  },
  {
    _id: "poocatbompent001a",
    image: poolproduct6,
    title: "Bomba de piscina OptiFlo",
    code: "",
    category: "Pentair",
    brand: "Pentair",
    subcategory: "OptiFlo",
    downLoad: pdf6,
  },
  {
    _id: "poocatbompent002a",
    image: poolproduct7,
    title: "Bomba de piscina SuperFlo",
    code: "",
    category: "Pentair",
    brand: "Pentair",
    subcategory: "SuperFlo",
    downLoad: pdf7,
  },
  {
    _id: "poocatbompent003a",
    image: poolproduct8,
    title: "Bomba de piscina WhisperFlo",
    code: "",
    category: "Pentair",
    brand: "Pentair",
    subcategory: "WhisperFlo",
    downLoad: pdf8,
  },
];

export default poolBombProductList;
