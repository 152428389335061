import React from "react";
import Productcard from "./Productcard";
import classes from "./Category.module.css";

const Category = (props) => {
  const findBrand = (brand) => {
    if (brand === "none") {
      return;
    } else
      return (
        <img
          className={classes.brandLogo}
          src={props.brand.image}
          alt={props.brand.alt}
        ></img>
      );
  };
  return (
    <section className={classes.store}>
      <h1>{props.title}</h1>
      {findBrand(props.brand)}
      <div className={classes.products}>
        {props.list.map((product) => (
          <Productcard
            id={product._id}
            key={product._id}
            productimg={product.image}
            productitle={product.title}
            productDownLoad={product.downLoad}
            productBrand={product.brand}
          ></Productcard>
        ))}
      </div>
    </section>
  );
};

export default Category;
