import number1 from "../../images/galleryImages/well/1.jpg";
import number2 from "../../images/galleryImages/well/2.jpg";
import number3 from "../../images/galleryImages/well/3.jpg";
import number4 from "../../images/galleryImages/well/4.jpg";
import number5 from "../../images/galleryImages/well/5.jpg";

const wellSliderList = [
  {
    _id: "well 001",
    image: number1,
    title: "Título de la imagen de Mantenimientos de Pozos",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "well 002",
    image: number2,
    title: "Título de la imagen de Mantenimientos de Pozos",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "well 003",
    image: number3,
    title: "Título de la imagen de Mantenimientos de Pozos",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "well 004",
    image: number4,
    title: "Título de la imagen de Mantenimientos de Pozos",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
  {
    _id: "well 005",
    image: number5,
    title: "Título de la imagen de Mantenimientos de Pozos",
    text:
      "Sobre la imagen, el lugar, el proyecto, el equipo y marca que se uso",
  },
];
export default wellSliderList;
